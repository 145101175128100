// https://reactrouter.com/web/example/auth-workflow
import {
  createContext,
  useContext,
  useState,
  ReactNode,
  useEffect,
} from 'react'
import { Redirect, RouteProps } from 'react-router'
import * as Sentry from '@sentry/capacitor'
import { SentryRoute } from '../../sentry'
import { getMeCached } from '../api'
import storage from '../storage'

interface AuthContext {
  initialized: boolean
  user: string | null
  login: (clientId: string, password: string) => Promise<void>
  logout: () => Promise<void>
}

const authContext = createContext<AuthContext>({} as AuthContext)

export function useAuth() {
  return useContext(authContext)
}

function useProvideAuth(): AuthContext {
  const [initialized, setInitialized] = useState<boolean>(false)
  const [user, setUser] = useState<string | null>(null)

  useEffect(() => {
    const effect = async () => {
      try {
        // Try to migrate from legacy storage. After this, we can get
        // credentials from new storage.
        //
        // TODO: This can be removed after rollout once we can assume most users
        // have installed the update.
        await storage.migrateCredentials()

        const { clientId } = await storage.getCredentials()
        Sentry.setUser({ username: clientId })
        setUser(clientId)
      } catch (err) {
        console.log(err)
        Sentry.captureException(err)
      } finally {
        setInitialized(true)
      }
    }
    effect()
  }, [])

  const login = async (clientId: string, password: string) => {
    await storage.saveCredentials(clientId, password)
    Sentry.setUser({ username: clientId })
    setUser(clientId)
  }

  const logout = async () => {
    await storage.clearCredentials()
    getMeCached.clear()
    Sentry.setUser(null)
    setUser(null)
  }

  return {
    initialized,
    user,
    login,
    logout,
  }
}

export function ProvideAuth({ children }: { children: ReactNode }) {
  const auth = useProvideAuth()
  return <authContext.Provider value={auth}>{children}</authContext.Provider>
}

// Redirects to /login when user is not authenticated
export function ProtectedRoute({
  children,
  ...props
}: {
  children: ReactNode
} & RouteProps) {
  const auth = useAuth()

  return (
    <SentryRoute
      {...props}
      render={({ location }) =>
        auth.user ? (
          children
        ) : (
          <Redirect
            to={{
              pathname: '/login',
              state: { from: location },
            }}
          />
        )
      }
    />
  )
}

import { useEffect } from 'react'
import { IonApp } from '@ionic/react'
import { TENANT, loadTenantConfig } from './config'
import { getPagesCached } from './lib/api'
import { ProvideAuth, useAuth } from './lib/providers/auth'
import { ProvideLoading } from './lib/providers/loading'
import { ProvideTenantConfig } from './lib/providers/tenantConfig'
import { ProvideUserConfig } from './lib/providers/userConfig'
import { useTrackPageview } from './lib/hooks/track-pageview'
import { usePushNotification } from './lib/hooks/push-notification'
import { setupIonicReact } from '@ionic/react'
import Router from './Router'

/* Core CSS required for Ionic components to work properly */
import '@ionic/react/css/core.css'

/* Basic CSS for apps built with Ionic */
import '@ionic/react/css/normalize.css'
import '@ionic/react/css/structure.css'
import '@ionic/react/css/typography.css'

/* Optional CSS utils that can be commented out */
import '@ionic/react/css/padding.css'
import '@ionic/react/css/float-elements.css'
import '@ionic/react/css/text-alignment.css'
import '@ionic/react/css/text-transformation.css'
import '@ionic/react/css/flex-utils.css'
import '@ionic/react/css/display.css'

/* Theme */
import('./theme/tenants/' + TENANT + '/variables.scss')
import('./theme/tenants/' + TENANT + '/app.scss')

loadTenantConfig().then((config) => {
  window.document.title = config.about.tagName
})

setupIonicReact()

const App: React.FC = () => {
  return (
    <IonApp>
      <ProvideAuth>
        <ProvideLoading>
          <ProvideTenantConfig>
            <ProvideUserConfig>
              <Router />
            </ProvideUserConfig>
          </ProvideTenantConfig>
        </ProvideLoading>
      </ProvideAuth>
    </IonApp>
  )
}

// Nested within router to allow access to history
export const RoutedApp: React.FC = () => {
  const { user } = useAuth()
  const setupPush = usePushNotification()

  useTrackPageview()

  useEffect(() => {
    setupPush()
    if (user) {
      getPagesCached() // Prefetch
    }
  })

  return <></>
}

export default App

import memoizee from 'memoizee'
import * as Sentry from '@sentry/capacitor'
import { TENANT, API } from '../config'
import storage from './storage'
import { trackAppEvent } from './analytics'

const API_VERSION = 'r65eb3653'

export async function postRegister(data: {}): Promise<Response> {
  return post(`${API}/user`, data)
}

export async function postActivate(
  clientId: string,
  data: {}
): Promise<Response> {
  return post(`${API}/user/${clientId}`, data)
}

export async function delActivate(clientId: string): Promise<Response> {
  return delAuthorized(`${API}/user/${clientId}`)
}

export async function postResetPassword(
  clientId: string,
  data: {}
): Promise<Response> {
  return post(`${API}/user/${clientId}/reset`, data)
}

export async function postToken(clientId: string, token: string) {
  return postAuthorized(`${API}/user/${clientId}/token`, { token })
}

export async function getLogin(clientId: string, password: string) {
  return get(`${API}/user/login`, {
    headers: {
      ...(await basicAuthHeaders(clientId, password)),
    },
  }).catch(logError)
}

export async function getConfig(): Promise<UserConfig> {
  return getAuthorized(`${API}/user/config`)
    .then(handleJsonResponse)
    .catch(logError)
}

export async function postVouchersRead(): Promise<{}> {
  return postAuthorized(`${API}/user/vouchers/read`).catch(logError)
}

export async function getMe(): Promise<Customer> {
  return getAuthorized(`${API}/prosync/customer/me`)
    .then(handleJsonResponse)
    .catch(logError)
    .then((attributes) => new Customer(attributes))
}

export const getMeCached = memoizee(getMe, {
  promise: true,
})

export async function patchMe(data: {}): Promise<Response> {
  return postAuthorized(`${API}/prosync/customer/me`, data, { method: 'PATCH' })
}

export async function getPages(): Promise<Page[]> {
  return getAuthorized(`${API}/about/pages`)
    .then(handleJsonResponse)
    .catch(logError)
}

export const getPagesCached = memoizee(getPages, {
  promise: true,
})

export async function getMenus(): Promise<Menus[]> {
  return getAuthorized(`${API}/about/menus`)
    .then(handleJsonResponse)
    .catch(logError)
    .then((data) => data['menus'])
}

export async function getNews(): Promise<News[]> {
  return getAuthorized(`${API}/about/news`)
    .then(handleJsonResponse)
    .catch(logError)
    .then((data) => data['news'])
}

export async function getEvents(): Promise<Event[]> {
  return getAuthorized(`${API}/about/events`)
    .then(handleJsonResponse)
    .catch(logError)
    .then((data) => data['events'])
}

export async function getReceipts({
  debit = false,
}: { debit?: boolean } = {}): Promise<Receipt[]> {
  const url = `${API}/prosync/receipts` + (debit ? '?debit=1' : '')
  return getAuthorized(url).then(handleJsonResponse).catch(logError)
}

export async function getReceipt(id: string): Promise<Receipt> {
  return getAuthorized(`${API}/prosync/receipts/${id}`)
    .then(handleJsonResponse)
    .catch(logError)
}

export async function getVouchers(): Promise<Voucher[]> {
  const url = `${API}/prosync/vouchers`
  return getAuthorized(url).then(handleJsonResponse).catch(logError)
}

async function getAuthorized(url: string, options = {}) {
  return get(url, {
    ...options,
    headers: {
      ...(await basicAuthHeadersFromStorage()),
    },
  })
}

async function postAuthorized(url: string, data = {}, options = {}) {
  return post(url, data, {
    ...options,
    headers: {
      ...(await basicAuthHeadersFromStorage()),
    },
  })
}

async function delAuthorized(url: string, options = {}) {
  return del(url, {
    ...options,
    headers: {
      ...(await basicAuthHeadersFromStorage()),
    },
  })
}

async function get(url: string, options = {}) {
  return $fetch(url, {
    method: 'get',
    headers: {
      Accept: 'application/json',
    },
    ...options,
  })
}

async function post(
  url: string,
  data: {},
  options: { headers: {}; method?: string } = { headers: {} }
): Promise<Response> {
  return $fetch(url, {
    method: 'post',
    body: JSON.stringify(data),
    ...options,
    headers: {
      ...options.headers,
      Accept: 'application/json',
      'Content-Type': 'application/json',
    },
  })
}

async function del(
  url: string,
  options: { headers: {}; method?: string } = { headers: {} }
): Promise<Response> {
  return $fetch(url, {
    method: 'delete',
    ...options,
    headers: {
      ...options.headers,
    },
  })
}

async function handleJsonResponse(res: Response) {
  if (res.ok) {
    try {
      const data = await res.json()
      return data
    } catch (err) {
      const reason = new ApiError({
        code: `EJSON${res.status}`,
        status: res.status,
        url: res.url,
      })
      return Promise.reject(reason)
    }
  } else {
    const apiError = new ApiError(await errorFromResponse(res))
    apiError.url = res.url
    return Promise.reject(apiError)
  }
}

async function logError(err: {}) {
  // Convert low-level error (e.g. if fetch rejects) to ApiError
  const apiError =
    err instanceof ApiError
      ? err
      : new ApiError({ ...{ code: 'ENET', status: 0 }, ...err })
  const { code, status, url } = apiError

  Sentry.captureException(apiError, {
    tags: {
      'api.code': code,
      'api.status': status,
      'api.url': url,
    },
  })
  trackAppEvent('error:api', {
    props: {
      'error:code': code,
      'error:status': status,
      'error:url': url,
    },
  })

  return Promise.reject(apiError)
}

const $fetch: typeof fetch = async (url, options) => {
  options = {
    ...options,
    headers: {
      ...options?.headers,
      'Accept-Version': API_VERSION,
      Tenant: TENANT,
    },
  }

  // Tag HTTP method in case request fails (method cannot be retrieved from response)
  Sentry.setTag('api.method', options.method)

  const res = await fetch(url, options)
  if (!res.ok) {
    console.error(res.url, res.status, res.statusText)
  }
  return res
}

async function basicAuthHeadersFromStorage() {
  const { clientId, password } = await storage.getCredentials()
  return basicAuthHeaders(clientId, password)
}

async function basicAuthHeaders(clientId: string, password: string) {
  // `btoa()` on the client-side encodes characters using the first byte of
  // their Unicode code point (it throws when the code point has two bytes).
  // This code point is equivalent to ASCII (bits 0-127, between 0x00 and
  // 0x07c) and Latin-1 Supplement (bits 128-255, between 0x80 and 0xff).
  // However, `require(basic-auth).parse()` on the server-side expects UTF-8
  // encoding. In UTF-8, the Latin-1 Supplement is encoded with two bytes,
  // ranging from 0xc280 to 0xc3bf.
  //
  //  require('basic-auth').parse('Basic ' + btoa('user:ümlaut123'))
  //  -> Credentials { name: 'user', pass: '�mlaut123' }
  //
  // `basic-auth` uses Buffer internally. Compare:
  //
  //  Buffer.from('ü', 'latin1') -> <Buffer fc>
  //  Buffer.from('ü', 'utf8') -> <Buffer c3 bc>
  //
  // https://stackoverflow.com/a/30106551
  // https://developer.mozilla.org/en-US/docs/Glossary/Base64#the_unicode_problem
  function b64EncodeUtf8(string: string): string {
    function bytesToBase64(bytes: Iterable<number>) {
      const binString = Array.from(bytes, (byte) =>
        String.fromCodePoint(byte)
      ).join('')
      return btoa(binString)
    }

    // Constituent bytes in UTF-8
    const bytes = new TextEncoder().encode(string)
    return bytesToBase64(bytes)
  }

  return {
    Authorization: 'Basic ' + b64EncodeUtf8(`${clientId}:${password}`),
  }
}

export async function errorFromResponse(
  res: Response
): Promise<{ code: string; status: number; errno?: string }> {
  // Default error used if no other error can be retrieved from API response
  // (e.g. when network error occurs)
  const defaultError = { code: 'EREMOTE', status: res.status }
  let error = defaultError

  // Try extracting specific `code` and `errno` if we received JSON from API
  if (res.headers.get('Content-Type')?.includes('application/json')) {
    try {
      error = { ...defaultError, ...(await res.json()) }
    } catch (err) {
      console.warn(
        "Non 2xx response is 'application/json' but JSON was invalid"
      )
      Sentry.captureException(err)
    }
  }

  return error
}

export class ApiError extends Error {
  code: string
  status: number
  url?: string

  constructor({
    code,
    status,
    url,
  }: {
    code: string
    status: number
    url?: string
  }) {
    const message = `Request failed with code ${code} and status ${status}`
    super(message)
    this.name = 'ApiError'
    this.code = code
    this.status = status
    this.url = url
  }
}

export class Customer {
  customerCardNumber!: string
  dateOfBirth!: string
  iban!: string
  directDebitAuthorization!: boolean
  loyalityPoints!: number
  bonusValue!: number
  revenue!: number
  name1!: string
  firstName!: string
  lastName!: string
  gender!: number
  street!: string
  zipCode!: string
  city!: string
  telephone!: string
  mobilePhone!: string
  email!: string
  number!: number
  branchNumber!: number
  subscriptionNewsletter!: boolean
  isStaff!: boolean

  constructor(attributes: Customer) {
    Object.assign(this, attributes)
  }

  get mobilepayEnabled(): boolean {
    return this.directDebitAuthorization && !!this.iban
  }

  get mobilepayCard(): string {
    if (this.mobilepayEnabled && this.customerCardNumber.length) {
      return String(this.customerCardNumber).padEnd(12, '0')
    } else {
      return ''
    }
  }
}

export interface Page {
  slug: string
  title: string
  menu: boolean
  body: string
}

export interface News {
  title: string
  description: string
  image: string
}

export interface Menus {
  title: string
  descriptions: string
  image: string
}

export interface Event {
  title: string
  description: string
  location: string
  date: string
  href: string
}

export interface Receipt {
  receiptNumber: number
  date: string
  time: string
  staff: string
  payment: string
  pendingDebit: boolean | null
  totalQuantity: number
  totalPrice: number
  positions: ReceiptPosition[]
  paymentMethods: ReceiptPayment[]
}

export interface ReceiptPosition {
  customerNumber: number
  articleNumber: number
  receiptPosition: number
  supplierArticleNumber: string
  quantity: number
  name: string
  size: string
  ean: number
  salePrice: number
  labelPrice: number
  deduction: number
  discount: number
  customerDiscount: number
  staffDiscount: number
}

export interface ReceiptPayment {
  description: string
  amount: number
  voucherNumber: number | null
}

export interface Voucher {
  customerNumber: number
  date: string
  number: number
  type: VoucherTypes
  valid: boolean
  validFrom: string
  validUntil: string
  value: number
  visible: boolean
}

type VoucherTypes =
  | 'birthday'
  | 'bonus'
  | 'other'
  | 'Bonus'
  | 'Geschenk'
  | 'Cafe'
  | 'Facebook'
  | 'Mein CJ Schmidt'
  | ''

export interface UserConfig {
  vouchers: {
    count: number
    unread: boolean
    upcoming: boolean
  }
  broadcast: {
    enabled: boolean
    title: string
    description: string
  }
  staff: boolean
}
